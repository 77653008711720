.on-hover-options {
  display: flex;
  gap: 10px;
  align-items: center;
  transition: all 0.15s;
}

.cursor-btn {
  cursor: pointer;
}

.calender {
  margin-right: 5px;
}

.newForm {
  display: flex;
  justify-content: flex-end;
}

.newForm .link {
  color: rgba(23, 22, 22, 0.766);
  text-decoration: underline;
}

/* Page container */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4; /* Light grey background for the entire page */
  min-height: 100vh;
  padding: 20px;
}

/* Heading and progress container */
.form-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin-bottom: 0px;
}

.form-title {
  color: black;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0px;
}

.form-progress {
  font-size: 1.2rem;
  color: rgb(92, 90, 90);
  align-self: flex-end;
  margin-bottom: 5px;
  font-weight: 500;
}

/* Form styling */
.form-wrapper {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  max-height: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.form-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 73vh;
}

/* Form field wrapper */
.field-wrapper {
  display: flex;
  flex-direction: column;
}

/* Labels */
.field-label {
  font-size: 0.9rem;
  color: rgb(92, 90, 90);
  margin-bottom: 2px;
  font-weight: 500;
}

.field-heading-label {
  font-size: 1.9rem;
  color: rgb(92, 90, 90);
  margin-bottom: 2px;
  font-weight: 100;
}

/* Inputs */
.field-input {
  width: 100%;
  padding: 5px;
  border-radius: 10px !important;
  border: 1px solid #ccc;
  font-size: 1rem;
  box-sizing: border-box;
  background-color: white;
  color: rgb(92, 90, 90);
}

.field-input option {
  font-family: inherit !important;
  font-size: 1rem;
  color: rgb(92, 90, 90);
}

/* Placeholder hover for input */
.field-input:hover {
  border: 1px solid #aaa !important;
}

/* Input focus styling */
.field-input:focus {
  outline: none !important;
}

.notes {
  height: 13vh;
  resize: none;
}
.symptoms {
  height: 20vh;
  resize: none;
}
/* Error messages */
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 3px;
}

.btn-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.btns-wrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.form-btns {
  width: 14rem;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px !important;
  border: 2px solid #092540;
  cursor: pointer;
  padding: 6px;
}

.next-btn {
  background-color: #092540;
  color: white;
}

.back-btn {
  background-color: white;
  color: #092540;
}

.add-btn {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px !important;
  border: 2px solid #092540;
  cursor: pointer;
  padding: 6px;
  background-color: white;
  color: #092540;
}

.option-value {
  color: rgb(92, 90, 90);
}

/* Wrapper for tabs */
.tabs-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 2px solid #092540;
  border-radius: 10px;
  overflow: hidden;
}

/* Tab button style */
.tab {
  font-size: 14px;
  flex: 1;
  text-align: center;
  cursor: pointer;
  background-color: white;
  color: #092540;
  border-right: 2px solid #092540;
}

.tab:last-child {
  border-right: none;
}

/* Tab active state */
.tab.active {
  background-color: #092540;
  color: white;
}

.dose-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 8px;
  margin-top: 10px;
  max-height: 150px;
  overflow-y: auto;
}
.dose-item {
  display: flex;
  align-items: center;
  background-color: #092540;
  color: white;
  padding: 5px;
  border-radius: 8px;
  position: relative;
}

.dose-text {
  margin-right: 30px;
}

.remove-button {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: rgb(115, 112, 111);
  border: none;
  color: white;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.option-label {
  display: flex;
  font-size: 0.9rem;
  color: rgb(92, 90, 90);
  font-weight: 500;
}

.checkbox-input {
  margin-right: 5px;
}

.slider-percentage {
  display: block;
  text-align: center;
  color: rgb(92, 90, 90);
  font-size: 14px;
  font-weight: bold;
}

.score-label {
  margin-left: 10px;
}

.score-label1 {
  margin-left: 1.8rem;
}

.dropdown-list {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1000;
  margin-top: 60px;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

/* concussion form detail page  */
.d-header {
  display: flex;
  justify-content: center;
}
.back-detail {
  margin-top: 5px;
  cursor: pointer;
  margin-right: 20px;
}

.concussion-form-detail {
  max-width: 1000px;
  margin: 30px auto 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-details-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.details-section {
  background-color: white;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.details-section.full-width {
  grid-column: 1 / -1;
}

.details-section h4 {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
  color: #444;
}

.form-detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px 0;
  border-bottom: 1px solid #f0f0f0;
}

.form-detail-label {
  font-weight: 500;
  color: #555;
  flex: 1;
  padding-right: 10px;
}

.form-detail-value {
  color: #333;
  text-align: right;
  max-width: 60%;
  word-break: break-word;
}

.symptoms-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.symptom-summary {
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.border-subject-group {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  .form-details-container {
    grid-template-columns: 1fr;
  }

  .symptoms-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .symptoms-grid {
    grid-template-columns: 1fr;
  }
}
